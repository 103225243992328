import { variables } from './variables'
import cssVars from 'css-vars-ponyfill'
export default function changeTheme(theme) {
  document.documentElement.setAttribute('data-theme', theme)
  localStorage.setItem('data-theme', theme)
  cssVars({
    watch: true,
    variables: variables[theme],
    onlyLegacy: false
  })
}
